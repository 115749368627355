const steps = {
  'discover-your-needs': {
    title: 'Discover your needs',
    description:
      'Each of your projects is handled with care and thorough research. In most cases, introductory workshops are organized, during which your ideas and the final effect of the project are discussed in detail. That’s when we get familiar with your project, your business goals, and your customers’ characteristics, needs, or problems. If necessary, we may identify the target group ourselves.',
    texts: [
      'We come up with personas that help us get to know potential customers and their motivations or barriers—as well as any opportunities to catch their eye. Moreover, we create user flows in order to better understand the behavior of potential users of the website. User flows allow us to assess and predict the potential customers’ actions even before sitting down to the drawing board. In our work, we apply the design sprint process because it helps us detect problems along the way and to set new trends by designing key functionalities and prototyping and testing numerous ideas. Additionally, we can set the requirements not only for the MVP, but also for all subsequent stages.',
      'As soon as we begin working together, you will meet the person in charge of the project. This will be your contact person for the duration—even after the project is complete.',
    ],
    footerList: [
      'Initial mock-ups',
      'Initial technical specifications',
      'Descriptions and user flows of the most important functionalities or processes',
      'Defined personas of your clients',
    ],
    image: '/images/process/discover-your-needs.jpg',
    buttonText: 'Dig into our discovery process',
  },
  'ui-ux': {
    title: 'UI/UX',
    description:
      'Brainstorms, creative ideas, specified target groups, immediate solutions... Sounds good? It’s only the beginning!',
    texts: [
      'We are aware that you would like to see the end result as soon as possible. Believe us, so do we! That’s why we provide you with professional mock-ups built on the solid foundation of your vision, our solutions, and—last but not least—UX principles.',
      'The prototype of your website allows us to show you its functionality, content distribution, and hierarchy. The application structure scheme is a satisfactory solution for both of us; by using it, we are able to check whether the project looks as good in reality as it does on paper. Moreover, thanks to mock-ups we can check the practicality and correctness of the user flow.',
      'The mock-up is interactive and we strive to make it as close to the end result as possible. Real user testing is also a possibility. It all boils down to the fact that should you want to make any changes, there is a quick, cost-efficient solution to do so.',
      'The prototype developed during the mock-up stage transforms into a fully-fledged project. We fine-tune each and every graphical element to meet your expectations. In order to adjust the website to the appropriate target group, we take into account their demographic data. At this stage, we advise you to use interactive solutions that will help us meet your business and image ambitions. Our mission is to make your digital product transparent, functional, useful, and esthetically pleasing!',
    ],
    footerList: [
      'A mock-up of your digital product',
      'Proven solutions',
      'Finalized graphic design',
      'Initial project appraisal and established timeframe',
    ],
    image: '/images/process/business-analysis.jpg',
    buttonText: 'Only better? Tell me the rest!',
  },
  'product-development': {
    title: 'Product development',
    description:
      'All stages of development are interrelated. Therefore, effective communication, our synergy, and our attention to detail are key to every single one of them. After the mock-ups and tests are done, the next step is designing and coding—incidentally, that happens to be what developers enjoy the most.',
    texts: [
      'We deal with the project holistically. Our team is comprised of both back-end and front-end developers. By keeping in mind the uniqueness of each project, we are able to choose suitable solutions. I​t is the individual approach that make us stand out. Your vision, original project, creative solutions, and the top-notch skills of our developers – are all necessary components of a digital product tailored to your needs.',
      'After the design phase is complete, we proceed to the coding. Our projects are based on Scrum methodology, which allows us to keep you updated on the progress we’ve made. We’ll inform you of the progress by an online meeting scheduled weekly or biweekly. The meetings will concern the functionalities and the upcoming stages of development. Keep in mind that our projects are never set in stone; we don’t limit ourselves by any means. This allows us to take swift and precise action should your project require fine-tuning.',
    ],
    footerList: [
      'Thorough digital product testing',
      'A functional product meeting your specifications, user stories, and expectations',
    ],
    image: '/images/process/product-development.jpg',
    buttonText: 'All the facets of our development process',
  },
  relations: {
    title: 'Relations',
    description:
      'We’re not your ordinary company, who cuts off clients after the project is finalized. We cherish each and every customer relationship. We believe that by doing so, we’re not only helping to develop a single product, but also—more importantly—your brand, company, or start-up.',
    texts: [
      'Both during the project and after its completion, you’ll stay in touch with the project manager, designers, and developers. We appreciate every bit of feedback you can send our way because it allows us to do our job even better.',
      'Our projects are built in such a way that makes any future development effortless. Each project employs the Git system, a tool where developers can track any changes made to the project. Moreover, the Version Control System is essential in storing, versioning, and documenting the product’s source code. That’s where CI/CD is implemented in order to facilitate the introduction of new functions and eliminate potential errors.',
      'Whenever we come up with new, exciting ideas for your project, you’ll be the first person we contact. Additionally, we’ll let you know how long the implementation process will take and how each change will affect the project. All of this information is stored in the necessary documentation, a summary of which is sent to you after the project is finalized. By then, we will also have talked about any future collaborations.',
    ],
    footerList: [
      'Around-the-clock contact with the project manager',
      'Meetings with the team working on your project',
    ],
    image: '/images/process/relations.jpg',
    buttonText: 'Want to create a relationship with us?',
  },
}

export type ProcessPageAlias = keyof typeof steps

export interface ProcessStepData {
  title: string
  description: string
  texts: string[]
  footerList: string[]
  image: string
  buttonText: string
}

export type ProcessStepsData = {
  [key in ProcessPageAlias]: ProcessStepData
}

const typedSteps = { ...steps } as ProcessStepsData

export { typedSteps as steps }

import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { up, down } from 'styled-breakpoints'
import isFunction from 'lodash/isFunction'
import { Brand } from '@components/Navbar'
import HR from '@components/HR'
import StyledLink from '@components/Link'
import styled from 'styled-components'
import { H2 } from '@components/Heading'
import { digitFormat } from '@utils/utils'
import { ProcessPageAlias, steps } from '@data/process'

const Container = styled.div<{ reverse?: boolean }>`
  --column-gap: 6rem;
  column-gap: var(--column-gap);
  display: grid;
  row-gap: 2.4rem;
  font-family: 'Neue Montreal';
  padding: 0 2rem;

  hr {
    margin: 4.8rem 0;
  }

  ${up('lg')} {
    --column-gap: 11.2rem;
    padding: 0 0 0 10vw;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Numbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16rem;
`

const TextRows = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7.6rem 0 20rem;
`

const Texts = styled.div`
  padding: 9.8rem 0 0;
`

const StyledHeading = styled(H2)`
  font-size: 4rem;
  line-height: -0.5px;
  margin: 0 0 2.4rem;
`

const Number = styled.div`
  letter-spacing: 0.02rem;
`

const StyledDescription = styled.p`
  font-size: 2rem;
  line-height: 4rem;
  letter-spacing: 0.02rem;
  color: var(--gray-5);
  margin-bottom: 4.8rem;
`

const ImageContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  background: hsl(0, 0%, 0%);
  background: linear-gradient(
    90deg,
    hsl(0, 0%, 0%) 64%,
    hsla(0, 0%, 0%, 0) 100%
  );

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.6;
  }

  ${down('md')} {
    display: none;
  }
`

const StyledText = styled.p`
  line-height: 3.2rem;
  letter-spacing: 0.02rem;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`

const CloseButton = styled(StyledLink)`
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 6.4rem;
  line-height: 8rem;
  font-weight: 200;
  letter-spacing: 0.4rem;
  color: hsla(0, 0%, 100%);
  z-index: 10;
`

const FooterListTitle = styled.p`
  margin: 0 0 3.2rem;
`

const FooterListItem = styled.p`
  font-size: 2rem;
  line-height: 4rem;
  letter-spacing: 0.02rem;
  color: var(--gray-5);
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`

export type ProcessPageProps = {
  alias: ProcessPageAlias
  onClose?: (() => void) | null
}

const ProccessPageLayout: React.FC<ProcessPageProps> = ({ alias, onClose }) => {
  const router = useRouter()
  const aliases = Object.keys(steps)
  const currentStep = aliases.findIndex((item) => item === alias) + 1
  const maxStep = aliases.length
  const { title, description, texts, footerList, image } = steps[alias]

  const handleCloseClick = (homepage = false) => {
    if (isFunction(onClose)) {
      onClose()
    }

    if (homepage) {
      router.push('/')
    }
  }

  const CloseButtonComponent = (
    <CloseButton onClick={() => handleCloseClick()}>close</CloseButton>
  )

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <Container>
        <TextRows>
          <Brand />
          <Numbers>
            <Number>{digitFormat(currentStep)} —</Number>
            <Number>/ {digitFormat(maxStep)}</Number>
          </Numbers>
          <Texts>
            <StyledHeading>{title}</StyledHeading>
            <StyledDescription>{description}</StyledDescription>
            <HR />
            {texts &&
              texts.map((text) => <StyledText key={text}>{text}</StyledText>)}
          </Texts>
          <HR />

          <FooterListTitle>What's included?</FooterListTitle>

          {footerList.map((text) => (
            <FooterListItem key={text}>{text}</FooterListItem>
          ))}
        </TextRows>
        <ImageContainer>
          {!isFunction(onClose) ? (
            <Link href={`/process#${alias}`} passHref>
              {CloseButtonComponent}
            </Link>
          ) : (
            CloseButtonComponent
          )}

          <img src={image} alt="" />
        </ImageContainer>
      </Container>
    </>
  )
}

ProccessPageLayout.defaultProps = {
  onClose: null,
}

export default ProccessPageLayout

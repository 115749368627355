import { useState } from 'react'
import Link from 'next/link'
import { ImageSet } from 'caravaggio-react'
import { CaravaggioOptions } from 'caravaggio-react/dist/urlBuilder'
import Button, { ButtonProps } from '@components/Button'
import { digitFormat } from '@utils/utils'
import { ProcessPageAlias, ProcessStepData } from '@data/process'
import PagePopup from './PagePopup'
import {
  Container,
  ImageColumn,
  TextColumn,
  Content,
  CountNumber,
  Title,
  Description,
} from './Step.style'

export interface StepProps {
  data: ProcessStepData
  alias: ProcessPageAlias
  currentNumber: number
  maxNumber: number
  reverse?: boolean
  staticPath?: boolean
  buttonTheme?: ButtonProps['theme']
}

const imageSets = (o: CaravaggioOptions['o']) => ({
  '300w': {
    opt: {
      o,
      rs: {
        s: '512x',
      },
    },
  },
  '576w': {
    opt: {
      o,
      rs: {
        s: '930x',
      },
    },
  },
  '992w': {
    opt: {
      o,
      rs: {
        s: '615x',
      },
    },
  },
})

const Step: React.FC<StepProps> = ({
  data,
  alias,
  currentNumber,
  maxNumber,
  reverse,
  staticPath,
  buttonTheme,
}) => {
  const { title, description, buttonText, image } = data
  const [pagePopupAlias, setPagePopupAlias] = useState<ProcessPageAlias | null>(
    null,
  )

  const handleClick = () => {
    setPagePopupAlias(alias)
  }

  const handleClosePopup = () => {
    setPagePopupAlias(null)
  }

  const ButtonComponent = (
    <a className="d-inline-block">
      <Button theme={buttonTheme} onClick={handleClick} text={buttonText} />
    </a>
  )

  return (
    <Container className="process-step" id={alias}>
      <TextColumn className="process-step__texts">
        <CountNumber>{digitFormat(currentNumber)} —</CountNumber>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>

          {staticPath ? (
            <Link href={`/process/${alias}`} passHref>
              {ButtonComponent}
            </Link>
          ) : (
            ButtonComponent
          )}
        </Content>
        <CountNumber>/ {digitFormat(maxNumber)}</CountNumber>
      </TextColumn>

      <ImageColumn reverse={Boolean(reverse)}>
        <ImageSet
          src={image}
          alt={title}
          loading="lazy"
          sets={[
            {
              type: 'image/webp',
              rules: imageSets('webp'),
            },
            {
              rules: imageSets('original'),
            },
          ]}
        />
      </ImageColumn>

      {!staticPath && (
        <PagePopup alias={pagePopupAlias} onClose={handleClosePopup} />
      )}
    </Container>
  )
}

Step.defaultProps = {
  buttonTheme: 'red',
  reverse: false,
  staticPath: false,
}

export default Step

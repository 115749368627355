import { cloneElement } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container } from '@components/styled'
import { emitter, EventName } from '@components/Cursor'
import StepsRenderer from './StepsRenderer'
import {
  Container as StepContainer,
  CountNumber as StepCountNumber,
} from './Step.style'

const SliderContainer = styled.div`
  position: relative;
  overflow: hidden;

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    ${up('xl')} {
      padding: 0 9.6rem;
    }
  }

  ${up('lg')} {
    ${StepContainer} {
      --column-gap: 6.4rem;
    }

    ${StepCountNumber} {
      margin-left: auto;
    }

    ${Container} {
      width: 80%;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 25%;
      top: 0;
      z-index: 10;
    }

    &::before {
      left: 0;
      background: linear-gradient(
        90deg,
        #f6f6f6 0%,
        rgba(246, 246, 246, 0) 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        270deg,
        #f6f6f6 0%,
        rgba(246, 246, 246, 0) 100%
      );
    }
  }
`

const ProcessSlider: React.FC = () => {
  return (
    <SliderContainer>
      <Container
        onMouseEnter={() => emitter.emit(EventName.SET_TEXT, { text: 'Drag' })}
        onMouseLeave={() => emitter.emit(EventName.SET_TEXT)}
      >
        <Swiper
          breakpoints={{
            992: {
              spaceBetween: 64,
            },
            1300: {
              spaceBetween: 0,
            },
          }}
          spaceBetween={32}
          grabCursor
          autoHeight
        >
          {StepsRenderer().map((step) => (
            <SwiperSlide key={step.key}>
              {cloneElement(step, {
                reverse: true,
                buttonTheme: 'white',
              })}
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </SliderContainer>
  )
}

export default ProcessSlider

import styled from 'styled-components'

const HR = styled.hr`
  border: none;
  height: 0.1rem;
  background: var(--gray-3);
  width: 100%;
  display: block;
`

export default HR

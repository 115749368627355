import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { H4 } from '@components/Heading'
import Text from '@components/Text'

export const Container = styled.div`
  --column-gap: 6rem;
  --row-gap: 4rem;
  display: grid;
  grid-gap: var(--row-gap) var(--column-gap);
  contain: content;

  ${up('lg')} {
    --column-gap: 11.2rem;
    --row-gap: 3.2rem;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ImageColumn = styled.div<{ reverse: boolean }>`
  border-radius: 0.8rem;
  overflow: hidden;

  /* Closest child */
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${up('lg')} {
    order: ${({ reverse }) => reverse && -1};
  }
`

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
`

export const Content = styled.div`
  padding: 3.6rem 0;
`

export const CountNumber = styled(Text)`
  margin: 0;
`

export const Title = styled(H4)`
  margin: 0 0 2.4rem;
`

export const Description = styled(Text).attrs({
  size: 'big',
  color: 'gray',
})`
  margin: 0 0 4rem;

  ${up('lg')} {
    margin: 0 0 5.6rem;
  }
`

import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { PropsWithoutRef } from 'react'
import Container from './Container'

export const Slim = styled(Container)`
  ${up('md')} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`

export const SlimInner = styled.div`
  ${up('md')} {
    grid-column: 3 / 11;
    margin: 0 -4rem;
  }
`

// TODO: fix any
const ContainerSlim: React.FC<PropsWithoutRef<any>> = ({
  children,
  ...props
}) => (
  <Slim {...props}>
    <SlimInner>{children}</SlimInner>
  </Slim>
)

export default ContainerSlim

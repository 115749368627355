import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const ContainerText = styled.div`
  max-width: 71.2rem;
  padding: 0 3.2rem;
  margin-left: auto;
  margin-right: auto;

  ${up('lg')} {
    padding: 0 2rem;
  }
`

export default ContainerText

import { ProcessPageAlias, steps } from '@data/process'
import Step from './Step'

const StepsRenderer = () => {
  const aliases = Object.keys(steps) as ProcessPageAlias[]

  return aliases.map((alias, index) => {
    const step = steps[alias]

    return (
      <Step
        key={alias}
        data={step}
        alias={alias}
        currentNumber={index + 1}
        maxNumber={aliases.length}
      />
    )
  })
}

export default StepsRenderer

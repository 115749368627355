import { ComponentType, useEffect, useState } from 'react'

import { ProcessPageAlias } from '@data/process'
import { createPortal } from 'react-dom'
import dynamic from 'next/dynamic'
import isNil from 'lodash/isNil'
import styled from 'styled-components'
import { useRouter } from 'next/router'

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: hsla(0, 0%, 100%);
  z-index: 90;

  > * {
    overflow: auto;
    height: 100vh;
  }
`

interface PagePopupProps {
  alias?: ProcessPageAlias | null
  onClose?: (() => void) | null
}

const PagePopup: React.FC<PagePopupProps> = ({ alias, onClose }) => {
  // TODO: fix any
  const [ProcessPage, setProcessPage] = useState<ComponentType<any> | null>(
    null,
  )
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      document.body.classList.remove('is-fixed')
      setProcessPage(null)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    if (isNil(alias)) {
      handleRouteChange()
    } else {
      // TODO: skeleton loading for slower connections
      const DynamicProcessPage = dynamic(
        () => import(`../../pages/process/${alias}`),
      )

      document.body.classList.add('is-fixed')
      setProcessPage(DynamicProcessPage)
    }

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [alias])

  return isNil(ProcessPage)
    ? ProcessPage
    : createPortal(
        <Container>
          <ProcessPage onClose={onClose} />
        </Container>,
        document.body,
      )
}

PagePopup.defaultProps = {
  alias: null,
  onClose: null,
}

export default PagePopup
